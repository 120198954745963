.waterfall-container {
    display: flex;
    flex-direction: row;
}

.bar-container {
    flex: 1;
}

.row {
    display: flex;
    height: 30px;
}

.alternating-rows:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.04);
}

.row-bar-container {
    display: flex;
    flex: 1;
    margin: 5px 0;
}

.row-bar {
    background-color: #22a7f0;
    min-width: 2px;
}

.row-label {
    height: 30px;
    padding: 5px;
    border-right: 1px solid #ccc;
}
