@import '../../theme/variables.module.scss';

.btn-level-2 {
    background-color: #ffffff !important;
    color: #000000e0 !important;
}

.btn-level-2:hover {
    border-color: #d9d9d9 !important;
}

.list-group-item {
    border: 1px solid rgba(0, 0, 0, .125) !important;
}

.collapse-level-2 .ant-collapse-content-box {
    padding: 0px !important;
}

.collapse-level-2 .ant-collapse-header-text {
    text-align: left !important;
}

.collapse-level-2 .ant-list .ant-spin-container {
    height: revert;
    overflow: revert;
    .ant-list-items{margin-top: 0;}
    li.level-3:hover{background: var(--volvo-color10); transition: all .3s ease-in-out;}

}

.collapse-level-2 .ant-list-bordered {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.level-btn-1 {
    position: absolute;
    top: 8px;
    right: 30px;

    .dropdown .btn:focus-visible{box-shadow: none; border-color: none; background-color: none;} 
}

.title-level-1 {
    text-align: left;
    width: 200px;
}

.step-header {
    display: flex;
    align-items: left;

    button {
        margin-left: 10px;
        margin-top: 5px;
    }
}

.level-3 {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    text-align: left !important;
}

.header-size {
    font-size: 24px !important;
}

.btn-icon {
    border: 0px;
    background: transparent !important;
    width: 24px;
    text-align: center;
    padding: 5px;
    color: var($color-primary);

    i.delete {
        // color: red;    
        color:var(--volvo-color-primary);   
    
    }

    i.edit {
        color:var(--volvo-color-primary);
    }
}

.ant-empty.ant-empty-normal {
    display: none !important;
}

ul.dropdown-menu {
    z-index: 99;
    position: absolute;
    min-width: 40px !important;
    // height: 70px;
    // background: white;
    top: 40px;
    left: 0px;
    visibility: visible;
    display: block;
    max-width: 40px;
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
        margin: 0px;
        text-align: center;
        padding: 0px;
    }

    :hover{ background:var(--volvo-color30);}
}

button.btn.btn-primary.dropdown-toggle {
    border: 0px !important;
    position: relative;
    background: transparent !important;
    width: 40px;
    text-align: center;

    &::after {
        content: "";
        border: 0px;
    }
    
}

.h7{
    font-size: 0.8rem;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.rotate-90 {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.image-size2
{
    width:45px;
    height: auto;
}

.cursor-pointer{
    cursor: pointer;
}
.custom-button {
    background: transparent;
    color: #000; 
}

.business-capability-master{
    .ant-space-gap-col-small {
        column-gap: 20px;
    }
}

.overflow-x{
    overflow-x: auto !important;
}

.slider-container{
    .ant-carousel .slick-prev::before,
    .ant-carousel .slick-next::before{
        content:""
    }
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next{
        font-size: 25px;
        color: #000;
        margin: 7px;
        top: 25%;
        transform: translate-x(-25%);
        
    }

    .slick-dots{display: none!important;}
}

.towerview_bx{width: 100%!important;}