.business-process-entry{
    .bg-efefef {
        background-color: #efefef ;
    }
    .ant-tabs-tab-active {
        .ant-tabs-tab-btn{
            background-color:var(--theme-color-secondry)!important;
        }
    }
    .fs-small{
        font-size: small;
    }
}
.business-process-entry{
    .ant-tabs-tab-btn{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    text-align: left !important;
    .ant-row {
        justify-content: revert;
    }
    .line-height-normal{
        line-height: normal;
    }
    .bg-efefef {
        background-color: #efefef ;
    }
    .ant-tabs-tab-btn {
        padding: 5px 25px;
        border: 1px solid #6c757d;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        background-color: #1F1F1F !important;
        color: #ffffff !important;
    }
}