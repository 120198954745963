@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "./theme/variables.module.scss";
@import 'bootstrap/dist/css/bootstrap.min.css';

body {
	background-color: $body-bg-color;
	// font-family: Roboto, Serif;
	// font-size: 1.075rem;
	margin: 0px;
	padding: 0px;
	font-family: 'Montserrat';
}


.scrollbar
{
	margin-left: 30px;
	float: left;
	height: 300px;
	width: 50px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 20px;
}

.force-overflow
{
	min-height: 450px;
}


::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
}
.pointer { cursor: pointer;}

.row{
  margin: 0;
}

.ant-btn {
  // background-color:var(--theme-color-primary)!important;
  // color: var(--white-color)!important;
}

.ant-btn:hover {
  // color: $color-white;
}

//  .ant-layout-content {
//   background-color: $color-white;
// }

.btn-primary { background-color: $color-primary !important; }

.btn-outline { 
  background-color: transparent !important; 
  border-color: $color-primary; 
  color: $color-primary !important;
}

.btn-outline:hover {
  background-color: $color-primary !important;
  color: $color-white !important;
}

.btn-success {
  background-color: $color-green !important;
  border-color: $color-green;
}

.bg-light {
  background-color: $color-light-gray;
}

.ant-card .ant-card-head{
  background-color: $color-light-gray;
}
table.dataTable thead tr th{
  background-color: $color-green;
  color: $color-white;
}

// .App {
//   text-align: center;
// }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cu-left-sidebar .ant-layout-sider-trigger{
  background: #00000040 !important;
  text-align: right;
  padding-right: 25px;
  transition: all 0.2s;
}

#cu-left-sidebar .ant-menu-title-content{
  flex: inherit !important;
  font-weight: 600;
  color: var(--alt-color3);

  span{display: flex;align-items: center;}
}

//bof::menu
.navbar_bx{display: flex; flex-direction: row; gap:20px; justify-content:flex-start;}
.navbar_bx .headerSearch{width: 20%; border: none;}
.navbar_bx .headerSearch span{border: 0;}
.navbar_bx .headerSearch .search-results{height: 300px; overflow-x: auto; background: var(--white-color); padding: 10px; width: 340px;  box-shadow: 0px 10px 7px 0px #0006; margin-bottom: 24px;}
.search-results ul li { display: flex;  justify-content: space-between;}
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon +span{margin-right: 5px;}
.ant-layout .ant-layout-sider-light{background: none;} 
.ant-layout .ant-layout-sider{padding-bottom: 80px;}     
#cu-left-sidebar img.side-menu-icon + .ant-menu-title-content::before{display: none;}
#cu-left-sidebar .ant-menu-dark .ant-menu-sub .ant-menu-item{padding-left: 8px !important; border-radius: 0; }
#cu-left-sidebar:hover .ant-menu-item-active, #cu-left-sidebar .ant-menu-item-selected{border-left: 10px solid; border-radius: 0; }
.cu-header-menus.ant-menu .ant-menu-item .ant-menu-item-icon svg{display: block; color: var(--white-color);}
.ant-menu .ant-menu-item .ant-menu-item-icon svg, .ant-menu-submenu >.ant-menu .ant-menu-item .ant-menu-item-icon{display: none;}
#cu-left-sidebar .ant-menu-item.ant-menu-item-selected > .ant-menu-title-content::before{border-color: var(--alt-color3);}
#cu-left-sidebar .ant-menu-item:hover .ant-menu-title-content::before{border-color: var(--alt-color3); transition: all .25s ease-in-out;}


.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu-title { display: flex; justify-items: center; align-items: center;}
#cu-left-sidebar .ant-menu-dark .ant-menu-sub{width:auto;  flex-direction: column;}
.ant-menu-dark, .ant-menu-dark>.ant-menu{background: none;} 


.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected{background: none;}
.ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item, .ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title{padding: 0px;}
.ant-menu-dark.ant-menu-horizontal >.ant-menu-item span{color: var(--white-color);}   
.ant-menu-horizontal .ant-menu-submenu .ant-menu-title-content{color: var(--white-color);}  
.ant-menu-horizontal .ant-menu-item > .side-menu-icon, .ant-menu .ant-menu-submenu-title .ant-menu-item-icon{
    color: #fff !important;
    fill: #fff !important;
    filter: invert(1) sepia(0);
    }
    
.cu-header-menus.ant-menu-horizontal .ant-menu-item .ant-menu-title-content{color: var(--white-color);}
.cu-header-menus.ant-menu-horizontal  .ant-menu .ant-menu-item .ant-menu-item-icon{
    color: #fff !important;
    fill: #fff !important;
    filter: invert(1) sepia(0);
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected:hover, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected:hover, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected:hover, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected:hover{background: var(--theme-color-secondry)!important;}

//nav_sidebar 
#cu-left-sidebar{background: var(--volvo-color-primary);
    .ant-layout-sider-trigger{position: fixed!important; bottom:0px;}
    .ant-menu-dark, .ant-menu-dark>.ant-menu{background: none;}
    }

//eof::menu


.highlight {
  background-color: #ffff00;
  padding: 0;
}

.recent-opened-scenario-click{
  white-space: nowrap;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-theme{
  background-color: #9ba894 !important;
  color: #fff !important;
}

.border-theme{
  border: 1px solid #9ba894 !important;
}
.btn-theme {
  color: #fff !important;
  background-color: #9ba894 !important;
  border-color: #9ba894 !important;
}
.btn-theme:hover {
  color: #fff !important;
  background-color: #9ba894 !important;
  border-color: #9ba894 !important;
}
.btn-check:focus + .btn-theme, .btn-theme:focus {
  color: #fff !important;
  background-color: #9ba894 !important;
  border-color: #9ba894 !important;
  box-shadow: 0 0 0 0.25rem #697165 !important;
}
.btn-check:checked + .btn-theme, .btn-check:active + .btn-theme, .btn-theme:active, .btn-theme.active, .show > .btn-theme.dropdown-toggle {
  color: #fff !important;
  background-color: #9ba894 !important;
  border-color: #9ba894 !important;
}
.btn-check:checked + .btn-theme:focus, .btn-check:active + .btn-theme:focus, .btn-theme:active:focus, .btn-theme.active:focus, .show > .btn-theme.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #697165 !important;
}
.btn-theme:disabled, .btn-theme.disabled {
  color: #fff !important;
  background-color: #9ba894 !important;
  border-color: #9ba894 !important;
}
.btn-outline-theme {
  color: #9ba894 !important;
  border-color: #9ba894 !important;
}
.btn-outline-theme:hover {
  color: #fff !important;
  background-color: #9ba894 !important;
  border-color: #9ba894 !important;
}
.btn-check:focus + .btn-outline-theme, .btn-outline-theme:focus {
  box-shadow: 0 0 0 0.25rem #697165  !important;
}
.btn-check:checked + .btn-outline-theme, .btn-check:active + .btn-outline-theme, .btn-outline-theme:active, .btn-outline-theme.active, .btn-outline-theme.dropdown-toggle.show {
  color: #fff !important;
  background-color: #9ba894 !important;
  border-color: #9ba894 !important;
}
.btn-check:checked + .btn-outline-theme:focus, .btn-check:active + .btn-outline-theme:focus, .btn-outline-theme:active:focus, .btn-outline-theme.active:focus, .btn-outline-theme.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem #697165 !important;
}
.btn-outline-theme:disabled, .btn-outline-theme.disabled {
  color: #9ba894 !important;
  background-color: transparent !important;
}
.ant-btn-primary.ant-btn-background-ghost{display: flex; align-items: center; justify-content: center;
  span{margin: 0!important;}
}


.ant-btn-circle{background: transparent; border-color:var(--theme-color-primary);}

.text-left{
  text-align: left !important;
}
.error-message {
  color: #ff4d4f;
}

.error-input {
  border-color: #ff4d4f;
}

.overflow-y{
  overflow: hidden;
  overflow-y: auto;
}

// New UI Css
// @import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
// :root{
//   font-family: Segoe UI;
// }


*{
  margin:0; 
  padding:0; 
  box-sizing: border-box; 
  box-shadow: none;
}
body{
  font-family: var(--theme-fontfamily)!important;  
  font-size: 62.5%;
}

:root{
  // --theme-fontfamily:"segoe ui", sans-serif; 
  // --theme-fontfamily:"Open Sans", sans-serif; 
  --theme-fontfamily:"Source Sans 3", sans-serif; 
  

  --theme-color-primary:#2a609d; 
  --theme-color-secondry:#2a609d;
  --theme-color-1:#e7e7e7;
  --txt-color-secondry:#525252;
  --txt-color-primary:#1f1f1f;
  --alt-color3:#f0f0f021;
  --alt-color1:#777777;
  --white-color:#ffffff;
  
  --fstxt-1:26px;
  --fstxt-2:16px;
  --fstxt-3:16px;
  --fstxt-4:13px;
  --fstxt-5:13px;
  --fstxt-6:12px;
  --text-txtbold:500;
  --alt-color6:rgba(255, 255, 0, 0.2);

  --theme-table-thead-th: #5a8dc7;
  --theme-table-tbody-th: #5a8dc7d6;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{ color: var(--theme-color-secondry); font-weight: 600;  padding-bottom: 5px;}

div, p, ul, li, ol, [class^="ant-col"], [class*=" ant-col"], [class^="ant-menu"], [class*=" ant-menu"]{font-size: var(--fstxt-5); color: var(--txt-color-secondry);}

.h1, h1{color: var(--theme-color-secondry);font-size: var(--fstxt-1); }
.h2, h2{color: var(--theme-color-secondry);font-size: var(--fstxt-2);}
.h3, h3{color: var(--theme-color-secondry);font-size: var(--fstxt-3);}
.h4, h4{color: var(--theme-color-secondry);font-size: var(--fstxt-4);}
.h5, h5{color: var(--theme-color-secondry);font-size: var(--fstxt-5);}
.h6, h6{color: var(--theme-color-secondry);font-size: var(--fstxt-6);}

.label-5{font-size: var(--fstxt-5); color: var(--txt-color-secondry);}
// .ant-btn-default{border-color: var(--theme-color-primary);}
.sec_fulheight{height: 90vh;}
.text-primary, .ant-btn-link{
  // color: var(--theme-color-primary) !important;
  color: var(--theme-color-secondry) !important;
}

.bg-primary{
  background-color: var(--theme-color-primary) !important;
}

.bg-compare-text{
  background-color: var(--alt-color6) !important;
}

// bof: Global Theme Layout 
.applicationlist-page{align-items: stretch; text-align: left;display: flex; flex-direction:row-reverse; min-height: calc(100vh - 30px);}
.applicationlist-page .integration-landscape{width: 100%!important;}
.report-wrapper{ width: 100%; padding: 0px;}
.applicationlist-page .left_pnlbx{
  border-left:6px solid var(--alt-color3); width: 100%; flex: 2;display:flex;
  .container-fluid .listview_bx{display: flex; flex-direction: column; justify-content: flex-start; /*margin: 0.5rem 1rem 0.5rem!important;*/ max-height: calc(100vh - 190px)!important; height: calc(100vh - 160px)!important; }
  .edit-enabled-list{ min-width:450px;max-width:450px;margin:0;}  
}
.applicationlist-page .right_pnlbx{background:var(--theme-color-1)!important; overflow-y: auto;}
.graph_container{max-height: calc(100vh - 160px);min-height: calc(100vh - 160px); overflow: auto;
  .ant-card-body{ padding: 15px;}
  .graph_viewlist{width:100%;}  
}

.title-headtag{padding: 0px 0px 10px 0px; font-size: var(--fstxt-3); font-weight: var(--text-txtbold); color: var(--txt-color-primary); border-bottom: 1px solid var(--alt-color3); margin-bottom: 10px;
                span{ color: var(--theme-color-primary);
                }
              }
// eof: Global Theme Layout 


.btn-outline-primary, .btn-outline-secondary{color: var(--theme-color-primary); border-color: var(--theme-color-primary);}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle{background: var(--theme-color-primary);border-color: var(--theme-color-primary);}
.btn:hover{background: var(--theme-color-primary); color: #fff;border-color: var(--theme-color-primary);}

div.headingbx{display: flex;  align-items: center;     align-content: space-between;     width: 100%;    justify-content: space-between; border-bottom: 1px solid var(--alt-color1); padding: 10px 0 0 0; margin-bottom: 10px;}
.ant-spin-container div div.portfolio-listbx{ padding: 10px 20px 10px 10px; transition: all .25s ease-out;
td:last-child{
  text-align: right;
}
}
.ant-spin-container div div.portfolio-listbx:hover{ transition: all .25s ease-out; background-color: var(--alt-color3);}
.portfolio-item{display:flex; flex-direction: row;}
.badge_id{ display: none; min-width:60px;    background:var(--alt-color3); color: var(--theme-color-secondry); padding: 2px; border-radius: 6px; font-size:var(--fstxt-6);text-align: center;}
.badge_id sup{    background: none !important; color:var(--theme-color-secondry) !important; box-shadow: none !important; padding: 0 6px!important;}
.des-bx{font-size:var(--fstxt-5); margin-left: 10px; display: flex; flex-direction: column;
h4.text-primary{color: var(--txt-color-primary)!important;}
}
.des-bx small{font-size:var(--fstxt-6)}
.portfolio-listbx {
  span svg{color: var(--theme-color-primary)};
  button.ant-btn-primary{
    span svg{color: var(--white-color);}
    
  }
}
.ant-spin-container div div.portfolio-listbx{border-bottom: 1px solid var(--alt-color3); }
button.ant-btn-primary{box-shadow: none; background: var(--theme-color-primary);}
// button.ant-btn-primary:hover{background: var(--theme-color-secondry)!important;}

// bof:Global Filter css
.ant-divider-horizontal{margin: 4px 0;}
.life-cycle-stages-filter{
  border:0;// border-bottom: 1px solid var(--alt-color1);
  margin-top: 6px;
  border-radius: 4px;
  .ant-collapse-header,
  .ant-collapse-item,
  .ant-collapse-content{
      border:0;
      background:var(--alt-color3);      
  }
  .ant-collapse-content{
    margin: 2px 2px 2px;
    background:var(--white-color);
}
.lcs-box{
    border: 1px solid #ddd;
    width: 15px;
    height:15px;
    margin: 2px; 
}

}

.applicationlist-page{
  
  .application-capability-grid{
      .bg-aaaaaa6b{
          background-color: var(--volvo-color10);
          margin: 10px 0 20px 0;
      }       
      .ant-select-selector{
          background: none;
          border-color: #9ba894;
      }
      .acg-table tbody{ 
          tr{
              border-bottom: 1px solid #ddd;
              td{
                  .card{
                      margin-right: 10px;
                      // margin-bottom: 10px;
                      min-width: 325px;
                  }
              }
          }
          .td-heading{
              // height: min(50vw, 120px); ;
              // max-width: 100px;
              padding: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center; 
              font-size:var( --volvo-ptxt-2);
              font-weight: var(--volvo-ptxtbold-2);
          }
      }
  }
  .application-capability-matrix{
      .bg-aaaaaa6b{ 
          background-color: var(--volvo-color3);
          margin: 10px 0;
      }

      .ant-select-selector{
          background: none;
          border-color: #9ba894;
      }
      .acg-table tbody{ 
          tr{
              td{
                  padding: 5px;
                  border-bottom: 1px solid #ddd;
                  vertical-align:top;
                  .card{
                      margin-right: 10px;
                      margin-bottom: 5px;
                      min-width: 250px;
                  }
              }
              td:first-child{
                  border-bottom: 0px;
                  height: min(50vw, 50px); ;
                  max-width: 100px;
                  padding: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  font-size: 1rem;
              }
          }
      }
  }
  .overflow-y{
      overflow: hidden;
      overflow-y: auto;
  }
  .life-cycle-stages-filter{
      margin-top: 10px;
      border-radius: 4px;
      background: var(--volvo-color10);
      .ant-collapse-header,
      .ant-collapse-item,
      .ant-collapse-content{
          border:0; 
          border-radius: 4px!important;
      }
      
      .ant-collapse-content{
          border:0;
          background: var(--white-color);
      }
      .lcs-box{
          border: 1px solid #ddd;
          width: 15px;
          height:15px;
          margin: 2px; 
      }

  }
  
}

//eof:Global Filter Css




.relationdetail_bx{border:0; border-radius: 0; padding: 0px; 
  // background: var(--txt-color-secondry);
  .ant-collapse {border: 0; border-radius: 0;  padding-bottom:0px;  border-bottom: 1px solid var(--alt-color3);  /*background:var(--txt-color-secondry);*/ margin-bottom:10px;
    .ant-collapse-item{border: none;}
    .ant-collapse-header{display: flex;align-items: center; 
      // color: var(--theme-color-primary);
      .ant-collapse-header-text{font-size: var(--fstxt-4); font-weight: var(--text-txtbold);}
      .ant-btn-primar{box-shadow: none;}
      
    }
    
    .ant-collapse-content {background: #fff; margin:0 4px 4px; padding: 0 0px;  border-radius: 0px !important; border-top: 0;
            :hover .detailslist-bx:hover{ padding:8px 20px; transition: all .2s ease-in-out; background: var;}
      .detailslist-bx{padding: 8px 20px; border-bottom:1px solid var(--alt-color3); display: flex; justify-content: space-between; align-items: center; transition: all .2s ease-in-out;
      button.ant-btn{border: none;}
      
      }
    }
    .ant-collapse-content-box{padding:0px 10px; 
      // border-bottom: 1px solid var(--alt-color3);
    :last-child div{border-bottom: none;}
  }
  }
}

.externallink_tbl .externallink_list{vertical-align: middle; border:1px solid var(--alt-color3); padding: 0 10px;border-radius: 6px;} 
.externallink_tbl button{color: var(--theme-color-primary);}
.externallink_tbl button.ant-btn-default{border: none;box-shadow: none;}
.externallink_tbl button.ant-btn-default span img{width: 20px; height: 20px;}
// digram-style
.diagramtxtcolr{color: #ed6913 !important;font-weight: 600;}

.txtdes p span{color:var(--theme-color-secondry)!important;font-weight: 600;}
.diagramlist{padding: 15px 10px; background: var(--alt-color3); border-radius: 6px;
div.card.card-body:last-child{margin-bottom: 0!important;}
div.card.card-body:hover{border-color: var(--theme-color-secondry); transition: all .2s ease-in-out; font-weight: 500;}
}

.fs-inherit{ font-size: inherit; }

// bof:scenario css

.scenario-replacewith{max-width: 82px; background: #86dcf9; margin: 0 10px; padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}
.scenario-upgradeto{background: #ff9ccc; margin: 0 10px; padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}
.scenario-retire{background: #FFB473; margin: 0 10px;padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}
.scenario-retain{background: #68c70f; margin: 0 10px;padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}

.scenario-color-replacewith{max-width: 82px; background: none; border-color: #86dcf9!important; color: #2cc4f7!important; margin: 0 10px; padding: 0px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}
.scenario-color-upgradeto{ background: none;color: #ff9ccc!important;border-color: #ff9ccc!important; margin: 0 10px; padding: 0px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}
.scenario-color-retire{ background: none;color: #FFB473!important;border-color: #FFB473!important; margin: 0 10px;padding: 0px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}
.scenario-color-retain{ background: none;color: #68c70f!important;border-color: #68c70f!important; margin: 0 10px;padding: 0px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap;}

.scenario-color-replacewith:hover{background: #86dcf9!important; color: #fff!important;}
.scenario-color-upgradeto:hover{background: #ff9ccc!important; color: #fff!important;}
.scenario-color-retire:hover{background: #FFB473!important;  color: #fff!important;}
.scenario-color-retain:hover{ background: #68c70f!important; color: #fff!important;}

.copyexiting_scenario{
  .ant-card-head{border-bottom: 0; border-radius: 0; min-height: auto; padding: 10px;}
  .ant-card-head-title{padding: 6px 0;}
  .ant-card-body{padding: 15px 10px;}
  .ant-card{background: none!important;}
}

.scenariolistbx{
  .ant-card-head{border-bottom: 0; border-radius: 0; min-height: auto;}
  .ant-card{ border: 0; border-radius: 10px; overflow: hidden;}
  .card-header{border: none;}
  .ant-card-body{padding: 0px 8px; border: 0;
    .card{border: none;
      .card-body{padding-top: 10px;}
      .card-header{background:var(--alt-color3); 
      h6, .h6{color: var(--theme-color-secondry);  font-size: var(--fstxt-5); cursor: pointer; }
      h6:hover, .h6:hover{color:var(--theme-color-primary); text-decoration: none ;}
    }
  }
  .scenario_listbtn{margin-top: 10px; border-top: 1px solid var(--alt-color3); padding-top: 10px; justify-content: space-between; display: flex;
    .btnbx button.btn{background: none; color: var(--theme-color-primary); border-color: var(--alt-color3); font-weight: 500;}
  }
}
}

.scenario_listbx{background: var(--alt-color3); padding: 1%; border-radius: 10px;
.ant-card{box-shadow: none; background: none; border: 0;}
.ant-card .ant-card-head{background: none; border: 0; font-size: var(--fstxt-3);}
.scenario_recentitem{text-align: left;
  span{text-align: left;}
  }
}
.scenario_viewitem{
  // margin-top: 20px; 
  padding-top: 10px;
  border-radius: 0; box-shadow: none!important; border-top: 1px solid var(--alt-color3);
  .ant-table-container{border-inline-start:none; border: 0;}
  .ant-card{box-shadow: none!important; background: none; border: 0;}
  .ant-card-head{background: none; border: 0; }
  .ant-table{border: 1px solid var(--theme-color-secondry); border-radius: 6px; overflow: hidden;}
  .ant-table-wrapper .ant-table-thead >tr>th{border-bottom: 1px solid var(--theme-color-secondry); background: var(--alt-color3);}
 
}




.ant-modal-content{ /*min-width: 460px;*/
  .ant-modal-close{position: absolute; top: -15px; right: -15px; background: var(--theme-color-primary); color: #fff; width: 30px; height: 30px;  border-radius: 100%;
    
    :hover{background-color: var(--theme-color-primary); color: #fff; border-radius: 100%;
      span{    width: 30px; height: 30px; text-align: center; display: flex;  align-content: center;  align-items: center;   justify-items: center; justify-content: center;  border-radius: 100%; 
      }
    }
  }
}



//scenario compare
.scenario-comitembx{overflow: hidden;
  .ant-table-container{border:0; border-radius:0 ;
   table{border: 0;
        .htitle{background: var(--alt-color3);  text-align: left!important;}
        .base_htitle{border-right-color: var(--alt-color3)!important; }
      }
      th, td{border-bottom-color: var(--alt-color3)!important; border-right-color: var(--alt-color3)!important;}      
    }
}
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container{border: 0; border-radius: 0;}

.fullscreen-enabled{
  background-color: #fff;
}

//bof:new side navbar
div[role~="menuitem"] + ul[role~="menu"]{ margin-left: 30px; 
li{padding: 0!important;}
}
aside.filter-ani{background: none;}
// .ant-layout .ant-layout-sider-children{padding-top: 15px;}
.ant-menu-light.ant-menu-inline .ant-menu-item, .ant-menu-light>.ant-menu.ant-menu-inline .ant-menu-item{position: relative;
  width: 166px;  margin-bottom: 4px;}
.ant-menu-inline-collapsed >.ant-menu-item, .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title{padding-inline: calc(40% - 8px - 4px);}
// #cu-left-sidebar .ant-menu-dark{ max-height: calc(100vh - 160px);}
// .ant-menu-dark.ant-menu-inline:first-child li.ant-menu-item:first-child {  border: none;}
.ant-menu-dark.ant-menu-submenu-popup>.ant-menu{background-color: none!important;}
.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{padding-left: 10px!important;}
.ant-menu-dark.ant-menu-submenu-popup>.ant-menu{background:#2a609d;.ant-menu-title-content{color:#fff;}.ant-menu-submenu-arrow{color:#fff;}}
.ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item{border-radius: 0; border-left: 10px solid; font-weight: 600; height: 36px; line-height: auto!important;}
.ant-menu-submenu-popup .ant-menu-vertical:hover .ant-menu-item-active, .ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item-selected{border-left: 10px solid; border-radius: 0;transition: all .25s ease-in-out; background: var(--theme-color-secondry); color: #fff;}
.ant-menu-dark .ant-menu-item-selected span.ant-menu-title-content, .ant-menu-dark>.ant-menu .ant-menu-item-selected span.ant-menu-title-content{color: #fff;}
.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item:hover{border-left: 10px solid;transition: all .25s ease-in-out; background: var(--alt-color3)!important; color: #fff;}
.ant-menu-dark.ant-menu-horizontal >.ant-menu-item, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu{padding: 0px 8px;}

//eof:new side navbar

//bof:right slide bar
.life-cycle-stages-filter{width: auto!important;}
.life-cycle-stages-filter .ant-collapse-item-active{border: 1px solid var(--alt-color3)!important;}
.life-cycle-stages-filter .ant-collapse-item-active .ant-collapse-header{background:var(--alt-color3); color: #fff; border-radius: 0px!important;}
.business-application-page .life-cycle-stages-filter .ant-collapse-header{border-radius: 0!important;}

// .life-cycle-stages-filter .ant-collapse-item-active .ant-collapse-header{background:var(--txt-color-secondry);}
.filter-ani{ /*width:calc(max(240px, 100% - 80px))!important;*/ padding: 0 10px; position: relative; max-height: calc(100vh - 40px); transition: all .3s ease-in-out;}


.filterbtn-bx{/*width:100%; position:sticky;*/ width: 230px; position: fixed; bottom: 0; display: flex; gap: 10px; justify-content: center; align-items: center; background:none; backdrop-filter: blur(10px);}
.filterbtn-bx button.ant-btn-default{background: var(--theme-color-primary); color: var(--white-color);
// :hover{color:var(--white-color);}
}
.filter-ani .ant-collapse-header-text{font-size: var(--fstxt-5); padding: 0; transition: all .3s ease-in-out;}
.filter-ani h4{font-size:var(--fstxt-6); padding: 0;}
.fieldinput{width: 100%; display: flex; flex-direction: column; gap: 5px;}
.fieldinput span{border: none; width: 100%;display: flex;  justify-content: space-between;font-size: var(--fstxt-6);}
.fieldinput  .anticon.anticon-close{text-align: right;justify-content: end;}
.fieldinput .anticon.anticon-close svg{padding: 2px; background: var(--theme-color-primary); fill: #fff!important; border-radius: 100%;}
.fieldsec_bx{padding-bottom:0px; width: 100%;}
.title_headingbx{width:100%; display: flex; gap: 10px; justify-content: space-between; align-items: center; }
.heading_sortbtn button{ width: 24px !important;  height: 24px;}
.fieldlist_item{background:var(--alt-color3); padding: 5px 5px 0 5px;
                  .title_headingbx {
                    h4{font-size: var(--fstxt-5); padding-bottom: 0;}
                    }
                }

.life-cycle-stages-filter label .ant-checkbox-wrapper{display: flex; justify-content:flex-start;}
// 200624
.app-actions { display: flex; /*flex-wrap: wrap; flex-direction: column;  gap: 5px;*/
  button{ width: auto; padding: 2px 8px;  margin:0px 2px!important; height: auto; color: var(--theme-color-secondry); font-weight: 600; border-radius: 6px; font-size: var(--fstxt-6); background: none;
              }
            }
//bof:030724

// bof: model box container

.ant-modal {position: fixed; left: 50%; top: 50%!important; transform: translate(-50%, -50%)!important; transition: all .35s ease-in-out;}
.ant-layout.ant-layout-has-sider{background: none;}
.modelpopup_bx{display: flex; flex-direction:row-reverse; align-items: stretch; }//height: calc(70vh - 10px);
.popup_leftpnlbx{ border-left:6px solid var(--alt-color3);/*width:calc(60vw - 60px);*/ flex:2;
  .listview_bx{display: flex; flex-direction: column; justify-content: flex-start; padding-right: 10px; padding-left: 10px; margin:0rem!important;  }//height: calc(55vh - 30px)!important;
  .int_listview_bx{display: flex; flex-direction: column; justify-content: flex-start; margin: 0.5rem 1rem 0.5rem!important; height: calc(70vh - 30px)!important; 
                  .bulk-entry-table .ant-table-body{ height: calc(55vh - 55px)!important;}
    }
}
.popup_rightpnlbx{background:var(--white-color); padding:0px 0px; /*min-width:calc(6vw - 60px); width:max(16vw, 100px);*/ height: calc(70vh - 30px); overflow-y: auto; position: relative;
  .right_pnlbx{padding: 0 10px;}
  .filter-ani{padding: 0; }
  aside.filter-ani{width: 200px!important; overflow: inherit!important;}
  .filterbtn-bx{width:100%; position:sticky; bottom: -1px; justify-content: center; align-items: center; background: #fff; backdrop-filter: blur(10px);}
}
 
.popup_headerbx { display: flex;  align-items: center;  padding:6px 10px;  margin:0px 0px 10px 0px;  justify-content: space-between; background: var(--alt-color3);
          .selectinput{min-width: 200px;}
          .formctrl form div.inputbx{  display: flex;  }
          .inputsec_bx{display: flex; gap: 5px; align-items: center; flex: 2;}
          h5{margin-bottom: 0;}
}

.bulk-operation {display: flex;align-items: center;  gap: 10px;} 
.bulk-operation select, .bulk-operation button { margin-left: 10px;}




.filterbx_sec{max-height: 70vh; display: flex; flex-direction: column; align-items: stretch;}
// .modelpopup_filterbx{
//   .filter-ani{max-height:70vh; height: 100%;  transition: all .3s ease-in-out; }
// }

.modelbx_listsec{height: calc(100vh - 10px);}
.ant-modal-root .ant-modal-centered .ant-modal{width:80%!important;
  .z-10.m-2.bg-white.h-full{margin: 0!important;}
  div#portfolioList{padding-left: 0;}
  div#portfolioList .ant-spin-container .overflow-y{padding-right: 15px;}
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm{ width: 30%!important;  display: flex; 
    .ant-modal-content{width: 100%;  justify-content: center;  justify-items: center;  display: flex;
    .ant-modal-confirm-btns{display: flex; align-items: center; justify-content: center;}
  }
}
.ant-modal.ant-modal-confirm.modal-justify-start{
  // all:revert;
  .ant-modal-content{
    display:block;
    .ant-modal-confirm-content{ margin-left: -30px; }
    .ant-modal-confirm-btns{ justify-content: end;}
  }
}
//eof:model popups
::-webkit-scrollbar-thumb
{
	background-color:#b9b9b9;
}

.cu-text-primary{
  color:var(--theme-color-primary) !important;
}
.ant-form-item{
  margin-bottom: 0;
}

//Report
.integration-landscape .application-card{ float: left; margin-right: 5px; margin-bottom: 5px; }//width: 120px; 
table.acg-table tr td{border-bottom: 1px solid var(--alt-color3); text-align: left; }

.group-1-column{background: var(--theme-table-tbody-th); border-radius: 6px; font-weight: var(--text-txtbold); padding: 0 10px;}

.acg-table{border-collapse: separate; border-spacing: 2px;}
.acg-table-print{ 
  thead{
    tr td,
    tr th{
      min-width: 150px;
      vertical-align: middle;
      padding-top: 0 !important;
      padding-bottom: 15px !important;
    }
  }
  tbody{
    tr td,
    tr th{
      vertical-align: middle;
      line-height: 1.3;
      padding-top: 0 !important;
      padding-bottom: 15px !important;
      a{
        text-decoration: none;
        div{
          margin:0;
          span{
            margin-top:-10px;
            line-height: 1;
            &:after{
              content: " ";
            }
          }
          &:last-child {
            margin-bottom: 5px;
          }
          
        }
      }
    }
  }
}
.page-break{
  margin-top: 10px;
}
.acg-table {
  tr th{
    padding:5px 10px; 
    color:#fff !important;///var(--txt-color-primary)!important; 
    font-weight: var(--text-txtbold);
    border-radius:5px; 
    font-size: 15px;
    a {
      color:#fff !important;
      .ant-typography{
        color:#fff !important;
      }
    }
  }
  thead{
    tr{
      th{
        font-size: 17px;
        &:nth-of-type(1) {
          background: var(--theme-table-thead-th);  
          border-bottom: 1px solid #ffffff;  
          padding-bottom: 10px;
          border-radius:5px; 
        }
        &:nth-of-type(2) {
          background:var(--theme-table-thead-th);  
          border-bottom: 1px solid #ffffff;  
          padding-bottom: 10px;
          border-radius: 5px; 
          text-align: center;
        }
      }
    }
  }
  a{
    &:hover{
      text-decoration: none;
      color: inherit;
    }
  }
}

.portfolio-option-color{
  min-width: 10px;
  min-height: 10px;
  background-color: var(--color,"#fff");
  display: block;
  margin-right: 5px;
  margin-top: 6px;
  float: inline-start;
  border-radius: 50%;
}
.business-capability-landscape-report{
  .card-item{
    $text-color:var(--text-color,"#000");
    color:var(--text-color,"#000");
    width:300px;
    background-color: var(--card-color,"#ddd");
    border-width: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    text-align: left;

    .relationship{
      margin-right: 5px;
      padding: 0px 5px;
      background-color: var(--portfolio-color, "#FFFFFF");
      border-radius: 2px;
      margin-left: 5px;
    }
    .list-group-item{
      padding: 5px 0px;
      &:last-child{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .level-1{
      font-size:var(--fstxt-4);
      font-weight:var(--text-txtbold);
      color: #FFFFFF;
      margin-bottom:10px;
    }
    .level-2{
      font-size:var(--fstxt-5);
      font-weight:var(--text-txtbold);
      background-color: #FFFFFF;
      margin-bottom:10px;
      border-radius: 5px;
      padding: 5px 5px;
    }
    ul{
      .level-3{
        font-size:var(--fstxt-5);
        margin-bottom: 0px;
        border-radius: 5px;
        padding: 0px 5px;
        color: #FFFFFF;
        display: flex !important;
        justify-content: flex-start !important;
        $portfolio-color:var(--portfolio-color,"#FFFFFF");

        &:before{
          content: "";
          width:10px;
          height: 10px;
          background-color: $portfolio-color;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}
.ant-upload-list-one {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #cccccca1;
  padding: 10px;
  max-width: 100vw;
  flex-wrap: wrap;
}

.ant-upload-list-two {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  max-width: 100vw;
  flex-wrap: wrap;
}

.custom-upload-header1, .custom-upload-header{
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  margin-right: 20px;
}

.ant-upload-list-item {
  margin-top: 0px !important;
}

.ant-upload-wrapper{
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
}


//scenario planing

.settings-menus .card-header .btnbx button {
  button:hover{border-color: var(--theme-color-secondry)!important; color: var(--theme-color-secondry)!important;}
  
}

.settings-menus .card-header .btnbx button.ant-btn-circle {border: none; background: none;}
.bulk-entry-table{
  table tr th:first-child, table tr td:first-child{width: 30px!important;}  
  .ant-table-wrapper .ant-table-bordered .ant-table-selection-col{width: 11px!important;}
}
// table >thead>tr>td{padding: 4px 8px!important;}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td{padding: 4px 8px!important;}
.intigration-bulk-entry-form .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td{padding: 0!important;}
col.ant-table-selection-col:first-child{width: 50px!important;}

// Differences 
.scenariolistbx .listview_bx{display: flex; gap: 10px;}
.difference-viewer {background:var(--alt-color3); border-radius: 10px;  padding: 10px; display: flex; gap: 10px; flex-direction: column; border: 1px solid var(--alt-color1);
  .card{border: none;
    .card-body{background: none; padding: 0;
      table tr th, table tr td{padding:6px 10px!important;}
      table tr th{background: #5b5b5b; color:var(--white-color);}
      .ant-table-wrapper .ant-table-pagination.ant-pagination{margin: 4px 0;}
    }
  }  

  .card{border: none;
      table tr th, table tr td{padding:6px 10px!important;}
      table tr th{background: #5b5b5b; color:var(--white-color);}
      .ant-table-wrapper .ant-table-pagination.ant-pagination{margin: 4px 0;}
   }  

   //diagram section
    .diagram-viewdifferent { background: none;
      .diagram-left{border:6px solid var(--alt-color3); padding:0px; background:var(--white-color);}
      .diagram-right{border:6px solid var(--alt-color3);  padding:0px;}
      .diagram-left h2, .diagram-right h2{ padding:10px; background: #5b5b5b; color: var(--white-color); border-radius: 10px 10px 0px 0px;}
    }   
}



//Businesss capability report
.portfolio-option-color{
  min-width: 10px;
  min-height: 10px;
  background-color: var(--color,"#fff");
  display: block;
  margin-right: 5px;
  margin-top: 6px;
  float: inline-start;
  border-radius: 50%;
}
.business-capability-landscape-report{
  .card-item{
    $text-color:var(--text-color,"#000");
    color:var(--text-color,"#000");
    width:300px;
    background-color: var(--card-color,#FF885B);
    border-width: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
 
    .level-1{
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom:10px;
    }
    .level-2{
      font-size: medium;
      font-weight: bold;
      background-color: #FFFFFF;
      margin-bottom:10px;
      border-radius: 5px;
      padding: 5px 5px;
    }
    ul{
      margin-bottom: 10px;
      .level-3{
        font-size: small;
        margin-bottom: 0px;
        border-radius: 5px;
        padding: 0px 5px;
        color: #FFFFFF;
        display: flex !important;
        justify-content: flex-start !important;
        $portfolio-color:var(--portfolio-color,"#FFFFFF");
 
        &:before{
          content: "";
          width:10px;
          height: 10px;
          background-color: $portfolio-color;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}

//Data completeness report
.data-completeness-landscape-report{
  .dc-heading-1{
    font-size: medium;
    font-weight: 700;
    padding: 7px 7px;
  }
  .view-details-table td,
  .view-details-table th{
    color: #aaa;
  }
  .close-icon{
    svg{
      fill:#ff9007;
    }
  }
}

.cu-text-ellipsis{
  text-overflow: ellipsis;
  width: var(--width,-webkit-fill-available);
  display: block;
  overflow: hidden;
  text-wrap: nowrap;
}
//setting update property
form.ant-form .card.card-body {max-height: 500px;  overflow-y: scroll;}
.cu-text-prewrap{ white-space: pre-wrap; }

.ant-list-footer{
  padding: 0 !important;
}


//bcl_resports 230924
.bcl_resports{
  .card-item{padding: 2px; border-radius: 10px; box-shadow: none;}
  .ant-card-small >.ant-card-body{padding: 0;}
  .card-item ul{margin-bottom: 0;}
  .card-item .list-group-item:last-child{border-radius: 0 0 7px 7px;}  
  
}

.cu-border-rounded{
  border-radius: 5px;
}

.notifications-page{
  .home-v2{
    flex-direction: column;
    .ant-tabs-tab-btn{
      .ant-tabs .ant-tabs-tab .anticon:not(:last-child) {
        margin-right: 2px;
      }
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      div{
        color: #FFFFFF;
      }
    }
  }
}
 
.ant-image-preview-operations-wrapper{
  .ant-image-preview-operations{
    background-color: #00000075;
    .ant-image-preview-operations-operation{
      .anticon {
        svg{
          fill:#fff;
        }
      }
    }
  }
}

.flex-row-reverse{
  flex-direction: row-reverse;
}
.custom-modal.ant-modal-confirm.ant-modal-confirm-confirm{width:35% !important;}
.radarview_bx #radar{height: 60vh;overflow: visible;width: 100%;}
.deletedNotify{background: red;color: #fff;padding: 1px 5px;text-transform: uppercase;border-radius: 3px;font-size: 11px;font-weight: bold;}

.sidemenu-new-blink{
  padding: 0.0em 0.4em 0.3em 0.4em;
  border-radius: 0.8em 0;
  position: relative;
  top: -5px;
  left: -3px;
  color: #fff;
  background: #ff6c00bd; animation: fadeOutCustom 3s 3;
}

@keyframes fadeOutCustom{
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.global-search{
  .ant-select-item{
    padding: 0px 5px;
    min-height: auto;
  }
}
.ant-menu-overflow-item-rest.ant-menu-submenu-horizontal{
  display: flex;
}

.checkbox-primary{
  .ant-checkbox-inner{
    border-color: var(--theme-color-primary);
  }
}

.slick-slider .remove-arrows:after{
  content: none;
}