.business-application-page{
    text-align: left;
    
    .application-capability-grid{
        .bg-aaaaaa6b{
            background-color: var(--volvo-color10);
        }       
        .ant-select-selector{
            background: none;
            border-color: #9ba894;
        }
        .acg-table tbody{ 
            tr{
                td:nth-child(1) {
                    position: sticky;
                    left: 0;
                    z-index:100;
                    // background-color: #ffffff;
                }
                td{
                    .card-group-2{
                        margin-right: 10px;
                        min-width: 250px;
                    }
                    a:hover{
                        text-decoration: none;
                    }
                }
                td:nth-child(2) {
                    border-bottom: 1px solid #ddd;
                }
            }
            .td-heading{
                min-height: min(50vw, 120px); ;
                // max-width: 100px;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                // font-size: 1rem;
                font-size:var( --volvo-ptxt-2);
                font-weight: var(--volvo-ptxtbold-2);
            }
            
        }
        .line-height-normal{
            line-height: normal;
        }
        .justify-content-left{
            justify-content: left;
        }
        .fw-500{
            font-weight: 500;
        }
        .cu-border-bottom{
            border-bottom: 1px solid var(--volvo-heading-border-color);
        }
        .cu-fs-smaller{
            font-size: smaller;
        }
        .cu-word-break-all{
            word-break: break-all;
        }
        .group-1-column{
            width: 150px;
            font-weight: 500;
            padding-left: 5px;
            padding-right: 5px;
            border: 2px solid rgb(221, 221, 221);
            background: rgb(178, 179, 229);
            border-radius: 6px;
        }
        .group-2-column{
            width: 150px;
            font-weight: 500;
            padding-left: 5px;
            padding-right: 5px;
        }
        .application-card{
            float: left;
            width: 120px;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
    .application-capability-matrix{
        .bg-aaaaaa6b{
            // background-color: #aaaaaa6b;
            background-color: var(--volvo-color10);
            margin: 10px 0;
        }

        .ant-select-selector{
            background: none;
            border-color: #9ba894;
        }
        .acg-table tbody{ 
            tr{
                td{
                    padding: 5px;
                    border-bottom: 1px solid #ddd;
                    vertical-align:top;
                    .card{
                        margin-right: 10px;
                        margin-bottom: 5px;
                        min-width: 250px;
                    }
                }
                td:first-child{
                    border-bottom: 0px;
                    height: min(50vw, 50px); ;
                    max-width: 100px;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 1rem;
                }
            }
        }
    }
    .overflow-y{
        overflow: hidden;
        overflow-y: auto;
    }
    .life-cycle-stages-filter{
        // border:0;
        // border-bottom: 1px solid #ddd;
        margin-top: 10px;
        border-radius: 6px;
        background: var(--volvo-color10);
        .ant-collapse-header,
        .ant-collapse-item,
        .ant-collapse-content{
            border:0; 
            border-radius: 0px!important;                  
        }
        .ant-collapse-content{
            border:0;
            margin: 1px 1px;
            background: #fff;

        }
        .ant-collapse-content.ant-collapse-content-active .ant-collapse-header{ background: var(--volvo-color30-light); border-radius: 0px!important; padding: 8px !important;} 
        .ant-collapse.ant-collapse-icon-position-end.ant-collapse-ghost{border-radius: 0;} 
        .lcs-box{
            border: 1px solid #ddd;
            width: 15px;
            height:15px;
            margin: 2px; 
        }

    }

    .cu-splitter:after{
        font-size: 20px;
        content: "|";
        line-height: 0;
        margin: 0 5px;
    }
}

.listview_bx{max-height: calc(100vh - 160px)!important;} 

.business-application-entry-page{
    .ant-tabs-nav-list{
        width:100%;
        .ant-tabs-tab-btn{
            white-space: nowrap;
            padding: 10px 15px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        text-align: left !important;
        .ant-row {
            justify-content: revert;
        }
        .line-height-normal{
            line-height: normal;
        }
        .bg-efefef {
            background-color: #efefef ;
        }
        .ant-tabs-tab-btn {
            padding: 4px 14px;
            border: 1px solid #6c757d;
            border-top-left-radius: 5px !important;
            border-top-right-radius: 5px !important;
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
            background-color: var(--theme-color-secondry) !important;
            color: #ffffff !important;
        }
    
        .panel .ant-collapse-header {
            font-size: 16px;
        }
    }
}
.cu-table-bordered{
    .error-cell{
        border: 2px solid #ff0000 !important;
        border-collapse:collapse ;
    }
    td,th{
        border: 1px solid #dee2e6;
    }
}
.bg-efefef {
    background-color: #efefef ;
}
.fs-small{
    font-size: small;
}
.readonly{
    pointer-events: none! important;
    cursor: not-allowed! important;
}

.swimlane_listbx{padding: 4px 0;}

.excel-list{overflow: auto;}

.coursor-pointer{ cursor: pointer; }

.fw-500{
    font-weight: 500;
}
.fs-smaller{
    font-size: smaller;
}
.vertical-align-baseline{
    vertical-align: baseline;
}