/* .step-container {
    display: flex;
    align-items: center;
  }
  
  .step {
    padding: 10px 20px;
    position: relative;
    background-color: #b4d5f5;  
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .step.active {
    background-color: #0056b3;  
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-left: 10px solid #1e90ff;  
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    margin-left: -5px;
  }
  
  .step-label {
    padding-right: 15px;
  }
   */

   /* Ashok Css */

   .clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
}

.container {
	font-family: 'Lato', sans-serif;
	width: auto;
	margin: 0 auto;
}

.wrapper {
	display: table-cell;
	height: 24px;
	vertical-align: middle;
}

.nav {
	margin-top: 30px;
}

.pull-right {
	float: right;
}

a, a:active {
	color: #333;
	text-decoration: none;
}

a:hover {
	color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
	font-size: 14px;
	text-align: center;
	color: #666;
	cursor: default;
	margin: 0 2px;
	padding: 7px 10px 7px 30px;
    height: 36px;
	/* min-width: 180px; */
	float: left;
	position: relative;
	background-color: #d9e3f7;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; 
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 17px solid #d9e3f7;	
	z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;	
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
	position: relative;
}

.arrow-steps .step span:before {
	opacity: 0;
	content: "✔";
	position: absolute;
	top: -2px;
	left: -20px;
}

.arrow-steps .step.done span:before {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease 0.5s;
	-moz-transition: opacity 0.3s ease 0.5s;
	-ms-transition: opacity 0.3s ease 0.5s;
	transition: opacity 0.3s ease 0.5s;
	
}

.arrow-steps .step.done {
	color: #fff;
	background-color: var(--theme-color-secondry);
}

.arrow-steps .step.done:after {
	border-left: 17px solid var(--theme-color-secondry);	
}

.arrow-steps .step.current {
	color: #fff;
	background-color: var(--theme-color-secondry);
}

.arrow-steps .step.current:after {
	border-left: 17px solid var(--theme-color-secondry);	
}


