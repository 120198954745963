.cu-table-bordered{
    .error-cell{
        border: 2px solid #ff0000 !important;
        border-collapse:collapse ;
    }
    td,th{
        border: 1px solid #dee2e6;
    }
}
.bg-efefef {
    background-color: #efefef ;
}
.fs-small{
    font-size: small;
}


.swimlane_listbx{padding:4px 0;}

.excel-list{overflow: auto;}

.app-buttons {
    display: flex;
    gap: 10px;
}