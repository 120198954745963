.integration-page{
    .fieldset {
        padding: 3px;
        width: 100%;
        border:dashed 2px #ddd;
        .fieldset-label {
            float: left;
            margin: -20px 20px;
            padding: 4px 6px;
            background: #fff;
            text-wrap: nowrap;
            font-size: inherit;
            font-weight: 600;
        }
    }
    .column-2{
        display: grid;
        grid-template-columns: auto auto;
    }
    
}

// .cu-modal-p-5 .ant-modal-content{
//     padding: 5px;
// }
.disable-item{
    pointer-events: none;
    background: #00000020;
}

.intigration-bulk-entry-form{
    .bulk-entry-table{
        table tbody tr td.ant-table-cell {
            padding:0px !important;
            .ant-select-selector,.ant-btn,input,textarea{
                border-radius: 0;
            }
        }
    }

    .ant-select.ant-select-open.ant-select-focused.ant-select-enabled
    .ant-select-selection.ant-select-selection--single
    .ant-select-selection__rendered
    .ant-select-selection-selected-value {
        visibility: hidden;
    }

    .ant-select.ant-select-open.ant-select-focused.ant-select-enabled
    .ant-select-selection.ant-select-selection--single
    .ant-select-selection__rendered
    .ant-select-selection-selected-value::before {
        visibility: visible;
        content: "Select a Person";
    }
}

.w-90{
    width:90%;
}