@import "../../../theme/variables.module.scss";

#cu-left-sidebar{
    .sidebar-nav {
        background-color: $nav-bar-bg-color;
        position: relative;
    }
    .sidebar-nav button {
        border-radius: 0;
        position: absolute;
        background-color: transparent !important;
        box-shadow: none;
        right: 15px;
    }
    .sidebar-nav button:focus, .sidebar-nav button:focus-visible{
        outline: none !important;
        outline-offset: unset !important;
        transition: none !important;
    }
    .sidebar-nav .ant-menu-tooltip-open {
        background-color: $color-green !important;
    }
    .sidebar-nav .ant-menu, .sidebar-nav .ant-menu .ant-menu-item-icon{
        font-size: 1rem !important;
    }
    .sidebar-nav .ant-menu .ant-menu-title-content{
        flex: none !important;
    }

    .ant-menu-dark, .ant-menu-dark .ant-menu-sub{
        background-color: var(--theme-color-secondry)!important;
        color: white;
    }
    .ant-menu-item-active,
    .ant-menu-item-selected {
        background-color: #2c2c2c !important;
        color: white;
    }

    .pt-2-5rem {
        padding-top: 2.5rem !important;
    }

    .side-menu-icon{
        color: #fff !important;
        fill: #fff !important;
        filter: invert(0) sepia(0);
    }

}


.filterbx{display: flex; flex-direction: row-reverse; transition: all .25s ease-in-out; margin-bottom: 15px;
    .animatefilter{transition: all .5s ease-in-out;}
    div.h3{padding-bottom: 0;}
}
// //bof::menu
// .ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu-title { display: flex; justify-items: center; align-items: center;}
// #cu-left-sidebar .ant-menu-dark .ant-menu-sub{width:auto;  flex-direction: column;}
// .ant-menu-dark, .ant-menu-dark>.ant-menu{background: none;} 


// .ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected{background: none;}
// .ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item, .ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title{padding: 0px;}
// .ant-menu-dark.ant-menu-horizontal >.ant-menu-item span{color: var(--white-color);}   
// .ant-menu-horizontal .ant-menu-submenu .ant-menu-title-content{color: var(--white-color);} 
// .ant-menu-horizontal .ant-menu-item > .side-menu-icon{
//     color: #fff !important;
//     fill: #fff !important;
//     filter: invert(0) sepia(0);
//     }
    
// .cu-header-menus.ant-menu-horizontal .ant-menu-item .ant-menu-title-content{color: var(--white-color);}
// .cu-header-menus.ant-menu-horizontal  .ant-menu .ant-menu-item .ant-menu-item-icon{
//     color: #fff !important;
//     fill: #fff !important;
//     filter: invert(1) sepia(0);
// }


// //nav_sidebar 
// #cu-left-sidebar{background: var(--volvo-color-primary);
//     .ant-layout-sider-trigger{position: fixed!important; bottom:0px;}
//     .ant-menu-dark, .ant-menu-dark>.ant-menu{background: none;}
//     }

// //eof::menu