.rotate-90 {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}



.level-grouping-table tr >th{
    width: 100px;
    height: 70px;
    text-wrap: wrap;
    word-wrap: break-word;
    text-align: center;
    font-family: var(--bs-body-font-family);
    font-size: small;
    font-weight: 500;
    vertical-align: middle;
    position: relative;
}

.indicator-btn {
    position: fixed;
    top: 45%;
    right: -15px;
    rotate: 90deg;
    margin-right: -18px;
}

.status-box{
    height: 20px;
    width: 20px;
    float: inline-start;
    margin-right: 5px;
}

.planuml-div{
    width: 600%;
}