.fishbone-chart{
    $color-line: #BABABA !default;
    $color-dot: #777777 !default;
    $color-text: #222222 !default;
    $chart-height: 100vh !default;
    $chart-width: 100vh !default;
    $timeline-width: 100px !default;
    $chart-legend-background: #FFFFFF !default;
    $background-color: #FFFFFF !default;
    $fishbonelink-color: #0000000d 0px 0px 0px 1px !default;

    // border: 1px solid;
    // border-color: #222222;
    height: var(--chart-height,$chart-height);
    width:var(--chart-width,initial);
    overflow: hidden;

    .slider-range{
        position: absolute;
        z-index: 1;
    }
    .fishbone-chart-legend{
        display: flex;
        justify-content: end;
        position: relative;
        margin: 0px;
        padding: 10px;
        ul{
            border: 1px solid #ddd;
            background-color: var(--chart-legend-background,$chart-legend-background);    
            margin: 0px;
            padding: 10px 20px 10px 10px;
            border-radius: 5px;
            position: absolute;
            z-index: 1;

            li{
                list-style-type: none;
                font-size: small;
                display: flex;
                align-items: center;
                font-weight: 500;
                .circle-dot {
                    background: var(--ligend-color,$color-dot);
                    width: 20px;
                    height: 20px;
                    float: left;
                    top:0px;
                    position: relative;
                    clip-path: circle(30%);
                    margin: 1px;
                
                }
            }
        }
    }
    .fishbone-chart-inner{
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--chart-height,$chart-height);
        width:var(--chart-width,$chart-width);
    
        ol {
            display: flex;
            width: auto;
            justify-content: space-between;
            position: relative;
        
            &:after {
                all: revert;
                content: "";
                display: block;
                width: calc(100% + $timeline-width/2);
                height: 3px;
                background-color: $color-line;
                position: absolute;
                top: 50%;
                left: -$timeline-width;
                transform: translate(0, -50%);
                z-index: -1;
            }
    
            li {
                list-style-type: none;
                position: relative;
                cursor: pointer;
                margin: 0;
                padding: 0;
                color: $color-text;
                font-family: verdana;
                font-size: 14px;
                width:$timeline-width;
                $color-dot:var(--dot-color,$color-dot);
    
                &:after {
                    all: revert;
                    content: "";
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $color-dot;
                    border: 2px solid $color-dot;
                    transition: background-color 0.2s ease;
                    position: relative;
                    z-index: 1;
                }
    
                &:nth-child(odd) > ol {
                    transform: rotate(210deg);
                    transform-origin: 0% 0%;
                    left: 7px;
                    top: 3px;
                    li span.content{
                        transform: rotate(-210deg);
                        transform-origin: 0% 0%;
                        position: absolute;
                        top: 30px;
                        left: 0px;
                    }
                }
    
                &:nth-child(even) > ol  {
                    transform: rotate(-30deg);
                    transform-origin: 0% 0%;  
                    left: 3px;
                    top: 5px;
                    li span.content{
                        transform: rotate(30deg);
                        transform-origin: 0% 0%;
                        position: absolute;
                        left: 25px;
                        top: -3px;
                    }        
                }
    
                .content-top{
                    top:-20px;
                    font-weight: 500;
                }
    
                ol{
                    display:block;
                    position: absolute;
                    display: block;
                    position: absolute;
                    width: 3px;
                    padding-inline-start: 0;
                    background-color: $color-line;
        
                    &:after{
                        content: "";
                        display: block;
                        width: 3px;
                        height: 3px;
                        background-color: $color-line;
                        position: absolute;
                        top: 50%;
                        left: 3px;
                        transform: translate(0, -50%);
                        z-index: -1;
                    }
        
                    li {
                        list-style-type: none;
                        position: relative;
                        cursor: pointer;
                        margin-top: calc($timeline-width*3/4);
                        padding: 0;
                        color: $color-text;
                        font-family: verdana;
                        font-size: 14px;
                        width:3px;
                        $color-dot:var(--dot-color,$color-dot);
                        
            
                        &:after {
                            content: "";
                            display: block;
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            background-color: $color-dot;
                            border: 2px solid $color-dot;
                            transition: background-color 0.2s ease;
                            position: relative;
                            left: -4px;
                        }
    
                        span.content{
                            text-align: left;
                            display: flex;
                            width: calc(2 * $timeline-width - 60px);
                        }
                    }
                }
            }
        }

        .time-not-defined-data{
            all: revert;
            // position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 100px;

            ol {
                all:revert;
                border-radius: 10px;
                width: 175px;
                padding: 15px 15px 15px 60px;
                background-color: #FFF5E4;
                clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 10% 100%, 0 50%);

                li:first-child{
                    list-style-type: none;
                    font-size: large;
                    margin-top: 0px;
                    line-height: 1;
                    position: relative;
                    font-weight: 500;
                    // left: -20px;
                    // border-bottom: 2px solid #9ba894;
                }
                li{
                    all: revert;
                    list-style-type: disc;
                    margin-top: 5px;

                    &::marker {
                        font-size: 1.5em;
                        color: var(--dot-color,$color-dot);
                        line-height: 0;
                        margin-right: 1ch;
                    }
                    &:after{
                        all:revert;
                    }

                    .fishbone-link{
                        all:revert;
                        font-size: 12px;
                        display: flex;
                        padding: 2px 7px;
                        background-color: #ffffff26;
                        border-radius: 5px;
                        box-shadow: var(--fishbonelink-color,$fishbonelink-color) 0px 0px 0px 1px;
                    }
                }
                &:after{
                    all:revert;
                }
                a{
                    white-space: initial;
                }
            }
        }
        
        span {
            position: absolute;
            top: calc(100% + 5px);
            left: 5px;
            transform: translate(-50%, 0);
            display: inline-block;
            text-align: center;
            z-index: 1;
            font-size: 10px;
        }
    }

    .fishbone-link{
        box-shadow: var(--fishbonelink-color,$fishbonelink-color) 0px 0px 0px 1px;
        background-color: var(--background-color,$background-color);
        padding: 5px 10px;
        border-radius: 5px;
        filter: drop-shadow(0px 2px 4px #ddd);
        font-size: 10px;
        a{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: -webkit-fill-available;
            max-width: 250px;
        }
    }
}
