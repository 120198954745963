@import "../../theme/variables.module.scss";

.dataTables_length label,
.dataTables_filter label {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.dataTables_length label select {
    width: 100px;
}

#int-app-table thead {
    position: sticky;
    top: 0px;
}


.pagination {
    color: $color-primary !important;
}

.active>.page-link {
    background-color: $color-primary !important;
}

a.page-link {
    color: $color-primary;
}

.paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    background-color: transparent !important;
    border: transparent !important;
    background: transparent !important;
    box-shadow: none;
}

.go-to-build-scenario,
.go-to-build-scenario:hover {
    background-color: transparent !important;
    border: none !important;
    color: $color-primary !important;
}

.delete-scenario,
.delete-scenario:hover {
    background-color: transparent !important;
    border: none !important;
    color: $color-error !important;
}

.badge-theme {
    background-color: $color-green;
    color: $color-white !important;
    cursor: pointer;
}

.ant-steps-horizontal {
    background-color: $color-light-gray;
    // padding-top: 0 !important;
}

.ant-steps-item-active::before {
    background-color: $color-green !important;
}

.ant-steps-item-icon {
    background-color: $color-green !important;
    border-color: $color-white !important;
}

.ant-steps-icon {
    color: $color-white !important;
}

.ant-form-item-label {
    text-align: start !important;
    font-weight: 600;
}

// .ant-row {
//     justify-content: center;
// }

.initiative-add-btn {
    position: absolute;
    top: -5px;
    font-size: 25px;
    margin-left: 10px;
    color: #2a609d;
    cursor: pointer;
    right: 10px;
}

.initiative-edit-btn {
    position: absolute;
    top: 25px;
    font-size: 25px;
    margin-left: 10px;
    color: #2a609d;
    cursor: pointer;
    right: 25px;
}

.ant-list .ant-list-header {
    background-color: $color-light-gray !important;
    font-weight: 500;
}

.ant-list .ant-list-items {
    margin-top: 5px;
}

.ant-list .ant-list-item.active {
    background-color: $color-light-gray;
}

.ant-list .ant-spin-container {
    height: 370px;
    overflow: auto;
}

.selected-col-list .ant-spin-container {
    height: 280px;
    overflow: auto;
}

.column-arrange-icon span {
    background-color: $color-green;
    margin-bottom: 10px;
    color: $color-white;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}

.add-new-column .ant-card-body {
    text-align: left;
    background-color: $color-light-gray;
    padding: 10px;
}

.edit-scenario {
    height: 600px;
    width: 85vw;
    overflow: auto;
}

span.no-show {
    display: none;
}

span.show-ellipsis:after {
    content: attr(data-title);
    background-color: #0a0a0a;
    color: #fff;
    font-size: 14px;
    position: absolute;
    padding: 1px 5px 2px 5px;
    top: 0;
    left: 0;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #ddd;
    z-index: 99999;
    visibility: hidden;
    white-space: normal;
    min-width: 150px;

}

span.show-ellipsis:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.1s;
    visibility: visible;
    width: -webkit-fill-available;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

[data-title] {
    position: relative;
}

table.dataTable>tbody>tr.selected>td.select-checkbox:after,
table.dataTable>tbody>tr.selected>th.select-checkbox:after {
    margin-top: -20px;
}

.a_datatbl_trash {
    float: right;
    margin-left: 7px;
}

.a_datatbl_trash .datatbl_trash {
    float: right;
    color: $color-error
}

div.dt-button-collection {
    height: 200px;
    overflow: auto;
}

.delete-col {
    display: flex;
    align-items: center;
}

.dt-avoid-y-scroll {
    overflow-y: hidden;
}

.ant-collapse-header-text {
    text-align: left;
    font-weight: 500;
}

.edit-scenario-icons {
    font-size: 22px;
    color: $color-black;
}

div.dtsp-panesContainer div.dtsp-searchPanes {
    flex-wrap: nowrap;
    overflow: auto;
}

div.dtsp-panesContainer div.dtsp-searchPanes div.dtsp-searchPane {
    margin: 0px 5px 0 0 !important;
    background-color: $color-green;
}

div.dtsp-searchPane div.dataTables_wrapper div.dataTables_scrollBody {
    height: 120px !important;
}

.dtsp-collapseButton {
    display: none !important;
}

div.dtsp-searchPane input.dtsp-paneInputButton:hover {
    background-color: unset;
}

.dtsp-search {
    color: $color-white;
    font-size: 14px !important;
    font-weight: 500;
    padding-top: 0;
}

.dtsp-buttonGroup button {
    color: $color-white !important;
    font-size: 20px;
}

.dtsp-search:focus-visible {
    outline-color: $color-white;
}

table.dataTable tbody tr.selected>* {
    box-shadow: inset 0 0 0 9999px $color-green;
}

div.dtsp-panesContainer {
    margin-top: -15px;
}

.sticky {
    position: sticky;
    top: 0;
}

.comparable-checked-scenarios {
    width: 15px;
    height: 15px;
}

#int-app-compare-table.table tbody tr td {
    background-color: transparent;
}

td {
    position: relative;
}

td.comment-added::after {
    content: "";
    width: 2px;
    height: 3px;
    position: absolute;
    top: 1px;
    right: -2px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #9ba894;
    clear: both;
    transform: rotate(224deg);
}

td.comment-added .comment-content {
    position: absolute;
    background-color: $color-white;
    padding: 10px;
    z-index: 99;
    border-radius: 10px;
    left: -70%;
    top: 48px;
    width: 300px;
    text-wrap: wrap;
}

td.comment-added .comment-content:before {
    content: "\A";
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent $color-white transparent transparent;
    position: absolute;
    top: -14px;
    rotate: 90deg;
    left: 45%;
}

.action-buttons {
    display: flex;
}

.action-buttons .reject-action {
    margin-right: 5px;
}

.action-buttons .approve-action {
    color: $color-white !important;
    background: $color-green;
}

.action-buttons a {
    text-decoration: none;
    border: 1px solid $color-green;
    padding: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.DTE_Form_Error {
    visibility: hidden !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0 !important;
}



// table.dataTable.nowrap td{

//     max-width: 200px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
    
// }

// table.dataTable.nowrap td:hover{

//     max-width: 200px;
//     overflow: unset;
//     text-overflow: ellipsis;
//     white-space: nowrap;
    
// }

.DTED_Lightbox_Wrapper{
    font-size: initial;
    .select2-container .select2-search--inline .select2-search__field {
        width: 100% !important;
    }
}


/*----------------*/
.app-list {
    width: 100%;
    margin: 0 auto;
  }
  
  .app-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .app-details {
    flex-grow: 1;
    margin-left: 10px;
  }
  
  .app-actions button {
    margin-left: 10px;
  }
  
 .btnAction{
    width: 100px;
 }
 .settings-menus{margin-bottom: 0px; border-bottom: 1px solid var(--alt-color3)!important;}
 .scenariolistbx .card-header{display: flex; align-items: center; justify-content:space-between;}
 .ant-collapse>.ant-collapse-item >.ant-collapse-header{align-items:center;}
 .scenariolistbx .card-header .btnbx{gap: 5px;display: flex;}
.ant-collapse{border: none; overflow: hidden;
    //.ant-collapse-header{background:#f7f7f7; border-radius:0 0 8px 8px!important; padding:8px 16px!important;}
    .ant-collapse-header{  border-radius:0 0 8px 8px!important; padding:6px 16px!important;}
    
    .ant-collapse-content-box{padding: 0!important;}
}
 .ant-collapse>.ant-collapse-item{ border: none; border-radius: 8px!important; /*background:#f7f7f7;margin-bottom: 5px; */}
 .ant-collapse-item.ant-collapse-item-active.panel{border-radius: 8px!important; overflow: hidden;
    .ant-collapse-header{background:#f7f7f7; border-radius:8px 8px 0 0!important;}
}


.ant-collapse-item.ant-collapse-item-active.panel div.card{border:none; margin:0px 20px;}


// .ant-modal-content{
//     width: 30vw;
//     min-height: 96px;
//     margin-left: auto;
//     //height: 96px;
// }


.ellipsis-text-300{
    content: attr(title);
    opacity: 1;
    transition: all 0.1s ease 0.1s;
    visibility: visible;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}



.scrollable-section {
    max-height: 200px; /* Adjust the height as needed */
    overflow-y: auto;
    padding-right: 10px; /* Optional: Adds space for scrollbar */
  }
  

  .new-button, .import-as-is-button, .import-other-scenario-button {
    padding: 2px 5px; /* Adjust padding for smaller size */
    font-size: 13px; /* Adjust font size */
}

.scenario-view-diffrenece{background: #01aecc; margin: 0 10px;padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap; color: #fff;}
.scenario-nochange{background: #ff37a5; margin: 0 10px;padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap; color: #fff;}
.scenario-removed{background: #e90303; margin: 0 10px;padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap; color: #fff;}
.scenario-modified{background: #ff9641; margin: 0 10px;padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap; color: #fff;}
.scenario-new{background: #4afa58; margin: 0 10px;padding: 0px 8px; border-radius: 25px; min-width: 50px;display: flex; justify-content: center;font-size:var(--fstxt-6); text-wrap: nowrap; color: #fff;}

.select-all-container {
    display: flex;
    align-items: center;
    justify-content: start; /* Aligns items to the left */
}

.form-row-splitter{
    .ant-form-item{
        margin-bottom: 8px !important;
        border-bottom: 1px solid #ddd !important;
        &:last-child{
            margin-bottom: 0 !important;
            border-bottom: 0px solid #ddd !important;
        }
    }
}