@import "../../../theme/variables.module.scss";


.text-blue {
    color: $color-primary !important;
}

.bg-none{
    background-color: transparent;
}
.swagger-ui{overflow:hidden;}
.swagger-ui .wrapper{max-width: 100vw !important;}
.swagger-ui .wrapper .block-desktop{width: 100vw !important;}
.header {
    padding: 0;
    z-index: 10;
    width: 100%;
    color: $color-theme-black;
    background:var(--theme-color-primary);
    position: fixed;
    top: 0;

    .menuBar {
        padding: 0;
        overflow: hidden;

        .ant-row {
            // align-items: center;
            justify-content: space-between;

            .content-end {
                justify-content: flex-end;
            }
        }
    }



    .logo {
        width: 220px;
        float: left;
        // display: flex;
        padding: 0 10px;

        a {
            display: flex;
            font-size: 40px;
            text-transform: capitalize;
            color: var(--white-color);
            letter-spacing: 2px;
            text-decoration: none;

            

            img {
                // width: 120px;
                margin-right:10px;
                height: 36px;
                position: relative;
                // top: 15px;
                // left: 31px;
            }
        }

        a h4{ color:var(--white-color)!important; text-decoration: none;}

    }

    .search-results{
        background-color: #ffffff;
        box-shadow: var(--bs-box-shadow) !important;
        padding: 5px;
        overflow-y: auto;
    }
}

a {
    color: $color-theme-black;
}

a:hover {
    color: $color-theme-black;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: $color-theme-black;
}

.hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $color-theme-black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after,
.hover-underline-animation:active:after,
.active:after{
    transform: scaleX(1);
    transform-origin: bottom left;
}

.cu-header-menus.ant-menu-horizontal{
    border-bottom:none !important;
    justify-content: end;
}
.cu-header-menus .ant-menu-title-content{
    color:$color-theme-black;
}
.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal{
    .ant-menu-submenu-title{
        display: flex !important;
        align-items: center !important;
        // height: 35px;
        color:#fff;
    }
}
 
svg[data-icon="select"] {
    color: $color-select-blue !important;
}

// Layout master
.loading_bx{height: 50px!important; width: 50px!important; position: fixed!important; z-index: 99999; top: 50%!important; left: 50%!important; transform: translate(-50%,-50%); }
.loading_bx_absolute{height: 50px!important; width: 50px!important; position: absolute!important; z-index: 99999; top: 50%!important; left: 50%!important; transform: translate(-50%,-50%); }
.master_wrapper{align-items: stretch; height: calc(100vh - 30px); margin-top: 36px;}
.main-wrapper{display: flex; flex-direction: column;  align-items: stretch; max-height: calc(100vh - 30px); min-height: calc(100vh - 30px); overflow-y: auto; }
.container-fluid.main-wrapper{padding: 0;}
//nav_sidebar 
#cu-left-sidebar{max-height:calc(100vh - 30px)!important; background: var(--volvo-color-primary);
.ant-layout-sider-trigger{position: fixed!important; bottom:0px;}
.ant-menu-dark, .ant-menu-dark>.ant-menu{background: none;}
}
.hide-div{display:none;}


//layout
//.ant-layout.ant-layout-has-sider{display: flex; align-items: stretch; flex-direction: row-reverse;}
.ant-layout.ant-layout-has-sider{display: flex; align-items: stretch;  }
.container_bx{height: calc(100vh - 4px);}

               


