.myLabelComponentInSvg {
  height: 70px;
  width: 180px;
  margin: 10px;
}

.cu-border-22{
  border: 1px solid;
  width: 22px;
  height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}