@import "../../theme/variables.module.scss";


.filter-btn {
    position: fixed;
    top: 40%;
    right: 0;
    rotate: 90deg;
    margin-right: -18px;
}

.ant-drawer-body {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.ant-tag {
    cursor: pointer;
}

.ant-tabs-nav-list {
    width: 120px;
}

.ant-tabs-tab {
    // white-space: pre-wrap;
    padding: 0 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn{
    background-color: $color-green !important;
    color: $color-white !important;
}
.ant-tabs-tab .ant-tabs-tab-btn{
    padding: 5px;
}

.prop-filter .ant-collapse-content-box {
    padding: 16px 0 !important;
}

.prop-filter .ant-collapse-content-box .prop-filter-tabs {
    max-height: 300px;
    overflow: auto;
}

.cityMap-submit {
    margin-top: 6px;
}


// Ogma Tool tip css starts

.ogma-tooltip {
    min-width: 250px;
    max-width: 350px;
    max-height: 280px;
    background-color: #fff;
    border: 1px solid #999;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    cursor: auto;
    font-family: Arial;
    font-size: 12px;
    text-align: left;
  }
  
  .ogma-tooltip .icon {
    font-family: FontAwesome;
  }
  
  .ogma-tooltip-header {
    font-variant: small-caps;
    font-size: 120%;
    color: #000;
    border-bottom: 1px solid #999;
    padding: 10px;
  }
  
  .ogma-tooltip-body {
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: inherit;
    max-height: 180px;
  }
  
  .ogma-tooltip-body th {
    color: #999;
    text-align: left;
    padding: 5px;
    border: 1px solid darkgray;
  }
  
  .ogma-tooltip-body td {
    padding: 5px;
    border: 1px solid darkgray;
  }
  
  .ogma-tooltip .ogma-tooltip-footer {
    padding: 10px;
    border-top: 1px solid #999;
  }
  
  .ogma-tooltip>.arrow {
    border-width: 10px;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  
  .ogma-tooltip.top {
    margin-top: -12px;
  }
  
  .ogma-tooltip.top>.arrow {
    left: 50%;
    bottom: -10px;
    margin-left: -10px;
    border-top-color: #999;
    border-bottom-width: 0;
  }
  
  .ogma-tooltip.bottom {
    margin-top: 12px;
  }
  
  .ogma-tooltip.bottom>.arrow {
    left: 50%;
    top: -10px;
    margin-left: -10px;
    border-bottom-color: #999;
    border-top-width: 0;
  }
  
  .ogma-tooltip.left {
    margin-left: -12px;
  }
  
  .ogma-tooltip.left>.arrow {
    top: 50%;
    right: -10px;
    margin-top: -10px;
    border-left-color: #999;
    border-right-width: 0;
  }
  
  .ogma-tooltip.right {
    margin-left: 12px;
  }
  
  .ogma-tooltip.right>.arrow {
    top: 50%;
    left: -10px;
    margin-top: -10px;
    border-right-color: #999;
    border-left-width: 0;
  }


//   Ogma tooltip css end

.legend-container {
  position: absolute;
  top: 10px;
  max-width: 200px;
  display: grid;
  grid-template-columns: auto;
  gap: 5px;
  border: 2px solid black;
  padding: 5px;
  background-color: #ffffffa7;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  left: 10px;
}

.legend-span-item {
  align-items: 'center';
  grid-column: 1/ span 2;
  text-transform: uppercase;
}

.legend-container .icon{
  font-family: "Font Awesome 5 Free";
} 

.active-node { border: 2px solid orange !important; }

.prop-filter-parent { 
  display: flex;
  align-items: center;
}

.prop-filter-parent label {
  margin-bottom: 0;
  margin-left: 5px;
} 

input[type=checkbox] {
  width: 15px;
  height: 15px 
}

.export-btn {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 0;
  right: 30px;
  border: none;
  width: 35px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}