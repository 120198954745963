.other-settings{
    text-align: left !important;
    .settings-menus{
        .ant-collapse-header-text{
            color: var(--volvo-color-secondry);
            font-weight: 600;
        }
    }

    .setting-content-tabs{
        .ant-tabs-tab {
            white-space: revert;
        }
        .ant-tabs-tab-btn {
            padding: 5px 25px;
            border: 1px solid #6c757d;
            border-top-left-radius: 5px !important;
            border-top-right-radius: 5px !important;
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
            background-color:var(--theme-color-secondry) !important;
            color: #ffffff !important;
        }
        .ant-tabs-nav-wrap {
            border-bottom: 1px solid var(--theme-color-secondry);
        }
        .application-tab-card{
            border:1px solid var(--volvo-color-secondry);
            .ant-card-head{
                background-color: #E3E3E3;
            }
        }
        .ant-collapse-header{
            align-items: center;
        }
    }
    .cursor-thumb{
        cursor: pointer;
    }
    .cu-border{
        border:1px solid #1F1F1F;
    }
    .active-list{
        background-color: #2a609d;
        color: #fff;
        padding: 5px;
        border-radius: 3px;
        font-weight: 500;
    }
    .cu-setting-menu-nav{
        padding: 5px !important;
        border-radius: 3px !important;
        font-size: 14px;
    }
    .portfolio-menus .cu-setting-menu-nav{
        color: #fff;
    }
    .overflow-y{
        overflow-y: auto;
    }
    .fw-500{
        font-weight: 500;
    }
    .dynamic-delete-button {
        position: relative;
        top: 4px;
        margin: 0 8px;
        margin-left: 5px;
        color: #999;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-collapse-content-box ul li{padding: 6px 0px 6px 20px; margin: 1px 0px 2px; border-radius: 6px; font-weight: 600;transition: all .3s ease-in-out;}
    .ant-collapse-content-box ul li:hover, .ant-collapse-content-box ul li.active-list{background:rgb(31 31 31 / 5%); transition: all .3s ease-in-out;
        color: var(--volvo-color-secondry); font-weight: 700; /*border-left: 5px solid; border-top-left-radius: 0; border-bottom-left-radius: 0;*/}
        
    .filter-ani{transition: all .2s ease-in-out;}
}


.setting_navpnl{min-width:160px!important; height: 90vh; padding: 0 15px; /*border-right:5px solid var(--volvo-color30)!important;*/}

//bof:addon css
// .setting_navlist ul > li::before{list-style: circle;display: flex; content: ""; width:10px; height: 10px; border: 1px solid var(--volvo-color50); display: inline-block; margin-right: 10px; border-radius: 100%;}
.setting_navlist ul > li{padding-left: 24px !important; transition: all .3s ease-in-out;}
.setting_navlist ul > li.active-list::before{border-color:var(--volvo-color-primary);}
.setting_carditem:hover{border-color:var(--volvo-color-primary); transition: all .3s ease-in-out;}

.setting_carditem div.d-flex.justify-content-between{align-items: center;}
//eof:addon css