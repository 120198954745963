@import "../../theme/variables.module.scss";


.dataTables_length label, .dataTables_filter label{
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.dataTables_length label select {
    width: 60px;
    margin-left: 5px;
    margin-right: 5px;
}

.pagination {
    color: $color-primary !important;
}
  
.active>.page-link{
background-color: $color-primary !important;
}

a.page-link{
    color: $color-primary;
}

.paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:active{
    background-color: transparent !important;
    border: transparent !important;
    background: transparent !important;
    box-shadow: none;
}
.go-to-build-scenario, .go-to-build-scenario:hover {
    background-color: transparent !important;
    border: none;
    color: $color-primary;
}

.delete-scenario, .delete-scenario:hover {
    background-color: transparent !important;
    border: none;
    color: $color-error;
}

.badge-theme{
    background-color: $color-green;
    color: $color-white !important;
    cursor: pointer;
}

.ant-steps-horizontal {
    background-color: $color-light-gray;
    // padding-top: 0 !important;
}
.ant-steps-item {
    // padding-top: 12px;
}
.ant-steps-item-active  {
    // background-color: $color-green;
}
.ant-steps-item-active::before {
    background-color: $color-green !important;
}
.ant-steps-item-icon {
    background-color: $color-green !important;
    border-color: $color-white !important;
}
.ant-steps-icon {
    color: $color-white !important;
}
.ant-form-item-label{
    text-align: start !important;
    font-weight: 600;
}
// .ant-row {
//     justify-content: center;
// }
div.ant-upload{
    width: 100%;
}

.mt-30{
    margin-top: 30px;
}