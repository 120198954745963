$color-white: #ffffff;
$body-bg-color: $color-white;
$color-theme-black: #000000f5;
$color-select-blue: #E8B386;
$nav-bar-bg-color: #fafafa;
$color-maroon: #ee4f4f;
$contact_email_bg: #101010;
$contact_address_bg: rgba(0, 0, 0, 0.65);
$contact_phone_bg: rgba(0, 0, 0, 0.65);
$color-error: #b70505;
$input-field: #efefef;
$btn-bg: #313131;
$color-green: #9ba894;
$color-secondary: rgba(0, 0, 0, 0.6);
$border-color: #c1c2c1;
$color-primary: #2a609d;
$color-bg: #f8f8f8;
$color-text: #373636;
$border-bottom: #707070;
$disable-text-color: #e3e3e3;
$golden-border: #ad9a68;
$input-disabled: #f5f5f5;
$color-black: #000000;
$mbsc-ios-accent:#ad9a68;
$color-table-blue: #bedaff;
$color-table-header: #c2c2c2;
$color-menu-bg: #f4f4f7;
$color-dark-gray: #b9bcb8;
$color-light-gray: #fafafa;

