.cu-fieldset{
    .fieldset {
        padding: 3px;
        width: 100%;
        border:dashed 2px #ddd;
        .fieldset-label {
            float: left;
            margin: -20px 20px;
            padding: 4px 6px;
            background: #fff;
            text-wrap: nowrap;
            font-size: inherit;
            font-weight: 600;
        }
    }
    .column-2{
        display: grid;
        grid-template-columns: auto auto;
    }
}

.justify-left{
    justify-content: left;
}