.plant-tree-page{
	.cu-fieldset{
		background-color: #fff;
		border-color:#ddd;
		.fieldset {
			padding: 6px 3px;
			width: 100%;
			border:solid 2px #ddd;
			border-radius: 5px;
			.fieldset-label {
				float: left;
				margin: -20px 5px;
				padding: 2px 6px 6px 6px;
				background: #fff;
				text-wrap: nowrap;
				font-size: inherit;
				font-weight: 600;
				border-radius: 5px;
				line-height:1.2;
			}
		}
		.column-2{
			display: grid;
			grid-template-columns: auto auto;
		}
	}
	.cu-tree-node{
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-top: -15px;
		border-color: rgb(221, 221, 221);
		text-align: center;
		padding: 3px 5px;
	}
}
