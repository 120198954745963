.start-new-scenario-v2{

}
.scenario-list-v2{
    
}
.cu-modal-p-0 .ant-modal-content{
    padding:0,
}
.cu-modal-p-5 .ant-modal-content{
    padding:5,
}

.cu-rotate-90{
    transform: rotateZ(90deg);
}