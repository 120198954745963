.compare-scenario-v2{
    .divider-dark{
        height: 1px;
        background-color: #1f1f1f;
    }
    .bg-gray-1{
        background-color: #dee2e66e;
    }
    .cu-prev-comparison{
        display: -webkit-box;
        gap: 16px;
        align-items: center;
        justify-content: left;
        .cu-prev-comparison-card{
            min-width: 150px;
        }
    }
}